.am-steps {
  font-size: 0;
  width: 100%;
  line-height: 1.5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.am-steps,
.am-steps * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.am-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
}
.am-steps-item:last-child {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}
.am-steps-item:last-child .am-steps-item-tail,
.am-steps-item:last-child .am-steps-item-title:after {
  display: none;
}
.am-steps-item-icon,
.am-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.am-steps-item-icon {
  border: 1px solid #bbb;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  text-align: center;
  font-size: 14px;
  margin-right: 8px;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.am-steps-item-icon > .am-steps-icon {
  line-height: 1;
  top: -1px;
  color: #108ee9;
  position: relative;
}
.am-steps-item-icon > .am-steps-icon .am-icon {
  font-size: 12px;
  position: relative;
  float: left;
}
.am-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}
.am-steps-item-tail:after {
  content: '';
  display: inline-block;
  background: #ddd;
  height: 1px;
  border-radius: 1px;
  width: 100%;
  -webkit-transition: background .3s;
  transition: background .3s;
  position: relative;
  left: -2px;
}
.am-steps-item-content {
  margin-top: 3px;
}
.am-steps-item-title {
  font-size: 16px;
  margin-bottom: 4px;
  color: #000;
  font-weight: bold;
  display: inline-block;
  padding-right: 10px;
  position: relative;
}
.am-steps-item-description {
  font-size: 15px;
  color: #bbb;
}
.am-steps-item-wait .am-steps-item-icon {
  border-color: #ccc;
  background-color: #fff;
}
.am-steps-item-wait .am-steps-item-icon > .am-steps-icon {
  color: #ccc;
}
.am-steps-item-wait .am-steps-item-icon > .am-steps-icon .am-steps-icon-dot {
  background: #ccc;
}
.am-steps-item-wait .am-steps-item-title {
  color: #000;
}
.am-steps-item-wait .am-steps-item-title:after {
  background-color: #ddd;
}
.am-steps-item-wait .am-steps-item-description {
  color: #000;
}
.am-steps-item-wait .am-steps-item-tail:after {
  background-color: #ddd;
}
.am-steps-item-process .am-steps-item-icon {
  border-color: #108ee9;
  background-color: #fff;
}
.am-steps-item-process .am-steps-item-icon > .am-steps-icon {
  color: #108ee9;
}
.am-steps-item-process .am-steps-item-icon > .am-steps-icon .am-steps-icon-dot {
  background: #108ee9;
}
.am-steps-item-process .am-steps-item-title {
  color: #000;
}
.am-steps-item-process .am-steps-item-title:after {
  background-color: #ddd;
}
.am-steps-item-process .am-steps-item-description {
  color: #000;
}
.am-steps-item-process .am-steps-item-tail:after {
  background-color: #ddd;
}
.am-steps-item-process .am-steps-item-icon {
  background: #108ee9;
}
.am-steps-item-process .am-steps-item-icon > .am-steps-icon {
  color: #fff;
}
.am-steps-item-finish .am-steps-item-icon {
  border-color: #108ee9;
  background-color: #fff;
}
.am-steps-item-finish .am-steps-item-icon > .am-steps-icon {
  color: #108ee9;
}
.am-steps-item-finish .am-steps-item-icon > .am-steps-icon .am-steps-icon-dot {
  background: #108ee9;
}
.am-steps-item-finish .am-steps-item-title {
  color: #000;
}
.am-steps-item-finish .am-steps-item-title:after {
  background-color: #108ee9;
}
.am-steps-item-finish .am-steps-item-description {
  color: #000;
}
.am-steps-item-finish .am-steps-item-tail:after {
  background-color: #108ee9;
}
.am-steps-item-error .am-steps-item-icon {
  border-color: #f4333c;
  background-color: #fff;
}
.am-steps-item-error .am-steps-item-icon > .am-steps-icon {
  color: #f4333c;
}
.am-steps-item-error .am-steps-item-icon > .am-steps-icon .am-steps-icon-dot {
  background: #f4333c;
}
.am-steps-item-error .am-steps-item-title {
  color: #f4333c;
}
.am-steps-item-error .am-steps-item-title:after {
  background-color: #ddd;
}
.am-steps-item-error .am-steps-item-description {
  color: #f4333c;
}
.am-steps-item-error .am-steps-item-tail:after {
  background-color: #ddd;
}
.am-steps-item.am-steps-next-error .am-steps-item-title:after {
  background: #f4333c;
}
.am-steps-item.error-tail .am-steps-item-tail:after {
  background-color: #f4333c;
}
.am-steps-horizontal:not(.am-steps-label-vertical) .am-steps-item {
  margin-right: 10px;
}
.am-steps-horizontal:not(.am-steps-label-vertical) .am-steps-item:last-child {
  margin-right: 0;
}
.am-steps-horizontal:not(.am-steps-label-vertical) .am-steps-item-tail {
  display: none;
}
.am-steps-horizontal:not(.am-steps-label-vertical) .am-steps-item-description {
  max-width: 100px;
}
.am-steps-item-custom .am-steps-item-icon {
  background: none;
  border: 0;
  width: auto;
  height: auto;
}
.am-steps-item-custom .am-steps-item-icon > .am-steps-icon {
  font-size: 22px;
  top: 1px;
  width: 22px;
  height: 22px;
}
.am-steps-item-custom.am-steps-item-process .am-steps-item-icon > .am-steps-icon {
  color: #108ee9;
}
.am-steps-small .am-steps-item-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 18px;
  font-size: 14px;
  margin-right: 8px;
}
.am-steps-small .am-steps-item-icon > .am-steps-icon {
  font-size: 12px;
  -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
          transform: scale(0.75);
  top: -2px;
}
.am-steps-small .am-steps-item-content {
  margin-top: 0;
}
.am-steps-small .am-steps-item-title {
  font-size: 16px;
  margin-bottom: 3px;
  color: #000;
  font-weight: bold;
}
.am-steps-small .am-steps-item-description {
  font-size: 12px;
  color: #bbb;
}
.am-steps-small .am-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
.am-steps-small .am-steps-item-tail:after {
  height: 1px;
  border-radius: 1px;
  width: 100%;
  left: 0;
}
.am-steps-small .am-steps-item-custom .am-steps-item-icon {
  background: none;
}
.am-steps-small .am-steps-item-custom .am-steps-item-icon > .am-steps-icon {
  font-size: 18px;
  top: -2px;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.am-steps-vertical {
  display: block;
}
.am-steps-vertical .am-steps-item {
  display: block;
  overflow: visible;
}
.am-steps-vertical .am-steps-item-icon {
  float: left;
}
.am-steps-vertical .am-steps-item-icon-inner {
  margin-right: 16px;
}
.am-steps-vertical .am-steps-item-content {
  min-height: 48px;
  overflow: hidden;
  display: block;
}
.am-steps-vertical .am-steps-item-title {
  line-height: 26px;
}
.am-steps-vertical .am-steps-item-title:after {
  display: none;
}
.am-steps-vertical .am-steps-item-description {
  padding-bottom: 12px;
}
.am-steps-vertical .am-steps-item-tail {
  position: absolute;
  left: 13px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 30px 0 4px 0;
}
.am-steps-vertical .am-steps-item-tail:after {
  height: 100%;
  width: 1px;
}
.am-steps-vertical.am-steps-small .am-steps-item-tail {
  position: absolute;
  left: 9px;
  top: 0;
  padding: 22px 0 4px 0;
}
.am-steps-vertical.am-steps-small .am-steps-item-title {
  line-height: 18px;
}
.am-steps-label-vertical .am-steps-item {
  overflow: visible;
}
.am-steps-label-vertical .am-steps-item-tail {
  padding: 0 24px;
  margin-left: 48px;
}
.am-steps-label-vertical .am-steps-item-content {
  display: block;
  text-align: center;
  margin-top: 8px;
  width: 100px;
}
.am-steps-label-vertical .am-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
.am-steps-label-vertical .am-steps-item-title {
  padding-right: 0;
}
.am-steps-label-vertical .am-steps-item-title:after {
  display: none;
}
